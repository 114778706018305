@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');


* { 
	box-sizing: border-box;
}

:root {
  --maroon- : #4A5EE6;
  --yellow- : #FFC107;
  --grey1- : #EAEAEA;
  --grey2- : #1E1E1E;
}

html, body {
	min-height: 100%;
  font-family: 'Montserrat', sans-serif;
}
	/* sets the body height of the browser, so that backgrounds and div heights work correctly. Color and background will almost certainly be altered; they are just placeholders */
	
body {
	font-size: 16px;
	text-rendering: optimizeLegibility;
}
	/* sets the base font size for the document; turns on kerning pairs and ligatures */
	
body, ul, ol, dl {
	margin: 0;
}
article, aside, audio, 
footer, header, nav, section, video {
	display: block; 
	}
	
	/* ensures that older browsers will display HTML5 elements correctly */
	
	/* begins to set up font sizes relative to base rem – h1 has been used as an example */
	
p { 
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
} 
	/* sets hyphenation by default on paragraphs */
	
textarea { 
	resize: vertical;
}
 /* changes textarea resizing from "both" (UA default) to vertical only */
 
table { border-collapse: collapse; }
td {
	padding: .5rem;
}
	/* causes tables to actually look like tables */
	
img { 
	border: none;
	max-width: 100%;
}
	/* removes outlines from linked images (for older browsers and IE), sets up images for responsive design layout */
	
input[type="submit"]::-moz-focus-inner, 
	input[type="button"]::-moz-focus-inner {
		border : 0px;
	}
	/* removes the inner border effect from focused buttons for form elements in Firefox */
	
input[type="search"] { 
	-webkit-appearance: textfield;
} 
input[type="submit"] { 
	-webkit-appearance:none;
}
	/* removes the OS X appearance from HTML5 search inputs and submit buttons when viewed in Safari */
	
input:required:after {
	color: #f00;
	content: " *";
}
	/* sets up required form fields with the conventional following red asterix */
input[type="email"]:invalid { 
	background: #4f4f4f;
}
	/* causes a visual alert in browsers that do client-side checking of HTML5 email field – this may already be the default in some browsers. */
	
.right { 
	float: right;
	margin-left: 2rem;
	clear: right;
}
.left { 
	float: left;
	margin-right: 2rem;
	clear: left;
}
	/* allows a quick shortcut for floating elements left and right while avoiding the “float quirk” problem */
	
sub, sup { 
	line-height: 0;
}

.navbar {
  background-color: #EAEAEA;
  padding: 20px 0
}

.nav-logo {
  color: var(--maroon-);
  font-weight:bolder;
  font-size: 1.2rem;
}

.link-item {
  color: #4f4f4f;
}

.nav-reg-button {
  background-color: var(--maroon-);
  color: #fff;
  border-radius: 5px;
  padding: 8px 12px;
  max-width: max-content;
}

.nav-reg-button:hover {
  color: #fff;
}

.partners-group {
  text-align: center;
  padding: 40px 0;
}

.navbar-collapse {
  flex-grow:unset;
  justify-content: flex-end;
}

.hero {
  background-color: var(--maroon-);
  padding: 40px;
  text-align: center;
}

.hero-title {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
}
.hero-theme {
  color: var(--yellow-);
  font-size: 1.2rem;
  font-family: 'Times New Roman', Times, serif;
}

.hero-date, .hero-location {
  color: #fff;
  font-size: 1rem;
  margin: 0;
}

.hero-register {
  background-color: var(--yellow-);
  color:#1E1E1E;
  text-decoration: none;
  padding: 8px 16px;
  display: block;
  margin: 20px auto;
  width: max-content;
  font-weight: 700;
  border-radius: 5px;
}

.about-mpa {
  background-color: var(--grey1-);
  padding: 40px 0;
}

.about-reps {
  padding: 40px 0;
}

.about-title {
  color: var(--maroon-);
  text-align: center;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 1.3rem
}

.about-content p {
  color: var(--grey2-);
  text-align: justify;
  padding: 0 40px;
}


.pastspeaker {
  padding: 40px 0;
}

.pastspeaker-title {
  color: var(--maroon-);
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.pastspeaker-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  gap: 10px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .pastspeaker-group {
    grid-template-columns: repeat(2, 1fr);
  }
}

.pastspeaker-name {
  font-size: 1.1rem;
  color: var(--maroon-);
  margin-top: 10px;
}

.pastspeaker-position, .pastspeaker-company {
  font-size: 0.8rem;
  color: #4f4f4f;
  margin-top: 10px;
  margin: 0;
}

/* speakers */
.speakers {
  background-color: var(--grey2-);
  padding: 40px 0;
}

.speaker-title {
  color: var(--maroon-);
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.speaker-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  gap: 10px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .speaker-group {
    grid-template-columns: repeat(2, 1fr);
  }
}

.speaker-name {
  font-size: 1.1rem;
  color: #fff;
  margin-top: 10px;
}

.speaker-position, .speaker-company {
  font-size: 0.8rem;
  color: #b3b3b3;
  margin-top: 10px;
  margin: 0;
}







.about-thinkmint {
  background-color: var(--yellow-);
  padding: 40px 0;
}


footer {
  background-color: #000;
  padding: 40px 0;
  text-align: center;
}

.footer-title {
  color: var(--yellow-);
  font-size: 1rem;
  font-weight: 600;
}

.footer-phone, .footer-email {
  color: #4f4f4f;
  margin: 0;
}

.copyright {
  margin-top: 20px;
  color: #4f4f4f;
}

.objectives {
  background-color: var(--grey1-);
  padding: 40px 0;
}

.objective-title {
  color: var(--maroon-);
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.objective-group {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 780px) {
  .objective-group {
    grid-template-columns: 1fr;
  }
}

.objective-ul {
  margin: 0;
  padding: 0;
}

.objective-li {
  list-style-type: none;
  margin-bottom: 10px;
}

.objective-image img {
  border-radius: 5px;
}

.programme {
  padding: 40px 0;
}

.plenary {
  padding: 40px 0;
}

.plenary-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width:760px) {
  .plenary-group {
    grid-template-columns: 1fr;
  }
}

.plenary-title {
  font-weight: 600;
}
.plenary1 {
  background-color: var(--yellow-);
}
.plenary2 {
  background-color: var(--maroon-);
}
.plenary2-title {
  color: #fff;
  font-weight: 600;
}
.plenary2-content {
  color: #fff;
}

.plenary-item {
  padding: 20px;
}
.plenary-title {
  font-size: 1rem;
}

.plenary-content {
  font-size: 0.8rem
}


.form-area {
  background-color: var(--grey2-);
  padding: 40px 0;
}

.form-title {
  color: #fff;
  font-size: 1.2rem;
}
 
.contact-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

@media screen and (max-width: 760px) {
  .contact-group {
    grid-template-columns: 1fr;
  }
}

label {
  color: #EAEAEA;
  margin: 5px 0;
  font-size: 0.8rem;
}

.form-firstname,
.form-lastname,
.form-email,
.form-phone {
  display: block;
  width: 100%;
  border-radius: 5px;
  border: none;
  padding: 8px;
  background-color: #4f4f4f;
  color: #fff;
  margin-top: 10px;
}

.submit-button {
  background-color: var(--yellow-);
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  margin-top: 5px;
}


.success-message {
  margin-top: 20px;
  border-radius: 6px;
  padding: 10px;
  color: #FFC107;
  width:max-content;
  text-align: center;
  font-size: 0.8rem;
  }

  .error-message {
    background-color: crimson;
    padding: 10px;
    color: #fff;
    width:max-content;
  }

  .reda-banner {
    display: block;
    margin: 20px auto;
  }

  .redabanner-group {
    text-align: center;
    margin: 0 auto;
  }